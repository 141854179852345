<template>
  <img src="~/static/general/user.svg" loading="lazy" alt="user">
</template>

<script>
export default {

};
</script>

<style>

</style>
