import Vue from 'vue';
import VCalendar from 'v-calendar';

Vue.use(VCalendar, {
  screens: {
    xxs: '375px',
    xs: '576px',
    sm: '770px',
    md: '990px',
    lg: '1190px',
    xl: '1190px',
    '2xl': '1190px',
  },
});
