<template>
  <div v-if="lang" class="language-flag">
    <img
      class="flag w-5 rounded"
      :loading="loadingType"
      :alt="lang"
      :src="`${$paths.assetsUrl}img/flags/${language}.png`"
    />
  </div>
</template>

<script>
export default {
  props: {
    lang: {
      type: String,
      default: () => null,
    },
    lazyLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    language() {
      return this.lang.includes('be') ? 'be' : this.lang;
    },
    loadingType() {
      return this.lazyLoading ? 'lazy' : 'eager';
    },
  },
};
</script>

<style lang="scss">
.language-flag {
  @apply shrink-0 px-0.5 py-0 rounded bg-white mr-2 inline-block min-w-[20px];
}
</style>
