<template>
  <div
    class="background-shadow fixed inset-0 transition-background ease-in-out duration-200 h-screen overflow-y-hidden"
    :class="{ 'pointer-events-none': !show }"
    :style="{ background }"
  />
</template>

<script>
export default {
  data() {
    return {
      show: false,
      maxZ: 10000,
      timeOut: 200,
      timeOutHandle: undefined,
      background: 'rgba(0, 0, 0, 0) center center/cover no-repeat',
    };
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type !== 'highlight/show' || this.show) {
        return;
      }
      clearTimeout(this.timeOutHandle);
      const [elCurr, elPrev] = state.highlight.elements;
      if (elCurr !== elPrev && elPrev && elPrev.highlightComp) {
        [elPrev.style.position, elPrev.style.zIndex] = [
          elPrev.highlightComp.position,
          elPrev.highlightComp.zIndex,
        ];
      }
      // eslint-disable-next-line nuxt/no-globals-in-created
      const computedStyles = window.getComputedStyle(elCurr);
      if (!elCurr.highlightComp) {
        elCurr.highlightComp = {
          zIndex: elCurr.style.zIndex,
          position: elCurr.style.position,
        };
      }
      elCurr.style.zIndex = this.maxZ;
      switch (computedStyles.position) {
        case 'absolute':
        case 'relative':
        case 'fixed':
          break;
        default:
          elCurr.style.position = 'relative';
          break;
      }
      this.show = true;
      this.background = 'rgba(0, 0, 0, 0.4) center center/cover no-repeat';
      if (!elCurr.skipScrollLock) {
        const elToScrollLock =
          elCurr.querySelector('[data-scroll-lock-target]') || elCurr;
        this.$disableBodyScroll(elToScrollLock);
      }
    });
    this.unsubscribeAction = this.$store.subscribeAction({
      before: (action, state) => {
        if (action.type !== 'highlight/hide') {
          return;
        }
        const [elCurr] = state.highlight.elements;
        this.show = false;
        this.background = 'rgba(0, 0, 0, 0.0) center center/cover no-repeat';
        clearTimeout(this.timeOutHandle);
        this.timeOutHandle = setTimeout(() => {
          if (this.show || !elCurr) {
            return;
          }
          if (!elCurr.highlightComp) {
            return;
          }
          [elCurr.style.zIndex, elCurr.style.position] = [
            elCurr.highlightComp.zIndex,
            elCurr.highlightComp.position,
          ];
        }, this.timeOut);
        this.$clearAllBodyScrollLocks();
      },
    });
  },
  beforeDestroy() {
    this.unsubscribe();
    this.unsubscribeAction();
  },
};
</script>

<style lang="scss">
.background-shadow {
  z-index: 9999;
}
</style>
