const deviceDetector = ({ $device }, inject) => {
  const isMobile = () => {
    if (process.server) return false;

    return $device.isMobile;
  };

  inject('isMobile', isMobile());

  inject('screens', (rule, defaultValue) => {
    if (isMobile()) return defaultValue;

    if (rule instanceof Object) {
      return Object.values(rule)[0];
    }
  });
};

export default deviceDetector;
