/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { getInstance as getBffInstance } from '~/services/bffService';
import { formatDate, addDays } from '~/helpers/formatDate.js';

export const state = () => ({
  languages: [],
  language: {},
  airports: [],
  inactiveAirports: [],
  airport: {},
  airportData: {},
  reviews: [],
  reviewsMeta: {},
  translations: {},
  pageContent: {},
  modalOpen: false,
  pageTemplate: {},
  faq: {},
  parkings: [],
  headerAirportsConfiguration: {},
  metaPages: {},
  airportReviewsPageContent: null,
  zendeskEnabled: false,
  airportDataContentLanguages: [],
  parkingsOffers: {},
  internalLinks: [],
  offerArrival: formatDate(addDays(new Date(), 1)),
  offerDeparture: formatDate(addDays(new Date(), 9)),
  flags: {
    experiments: {},
    features: {},
  },
});

function assignDataForDABucket(state, bucketState) {
  // domain
  bucketState.domain.country = state.language.country.name;
  bucketState.domain.country_code = state.language.country.code;
  bucketState.domain.language = state.language.lang;
  bucketState.domain.name = state.language.domain;
  //
  bucketState.airport.name = state.airport.commonName;
  bucketState.airport.id = state.airport.id;
  bucketState.airport.slug = state.airport.slug;
  bucketState.airport.commonName = state.airport.commonName;
  bucketState.airport.devtitle = state.airport.devtitle;
  bucketState.airport.internationalAirportName =
    state.airport?.internationalAirportName;
  //
  bucketState.review_score = state.reviewsMeta?.reviews?.score;
  bucketState.review_amount = state.reviewsMeta?.reviews?.count;
  //
  bucketState.currency = state.language.currency.iso_code;
  //
  bucketState.item_category = `${state.language.country.name} - ${state.airport.name}`;
  bucketState.item_list_name = state.airport.devtitle;
  bucketState.category = `${state.language.country.name}/${state.airport.name}`;
}

// export const mutations = {}
export const getters = {
  isExperimentEnabled: (state) => (name) => {
    const { features } = state.flags;

    return !!features?.[name];
  },
  experimentVariant: (state) => (name) => state.flags.features?.[name],
};

export const actions = {
  experimentVariant({ state, commit }, { name = '', condition = true }) {
    if (!condition) {
      return undefined;
    }
    commit('experiments/addExperiment', {
      name,
      variant: state.flags.features?.[name],
    });
    return state.flags.features?.[name];
  },
  setExperiments({ state }, req) {
    try {
      if (req.headers?.['x-features']) {
        state.flags.features = JSON.parse(req.headers['x-features']);
      }

      if (req.headers?.['x-experiments']) {
        const result = {};
        req.headers?.['x-experiments'].split(',').forEach((element) => {
          const [elKey, elValue] = element.split('=');
          result[elKey] = elValue;
        });
        state.flags.experiments = result;
      }
    } catch (error) {
      console.error(error);
    }
  },
  async nuxtServerInit({ state, dispatch }, { app, route, req }) {
    // Do not run nuxtServerInit for /healthz/info
    if (route.name === 'healthz-info') return;

    console.log('[nuxtServerInit] start ', req.originalUrl);

    const getReferer = () => `https://${app.$paths.urlEnvPrefix}${app.$paths.host}${req.originalUrl}`;

    const bff = getBffInstance('bff', {
      headers: {
        Referer: getReferer(),
      },
    });

    dispatch('setExperiments', req);

    try {
      if (req.headers?.['x-features']) {
        state.flags.features = JSON.parse(req.headers['x-features']);
      }

      if (req.headers?.['x-experiments']) {
        const result = {};
        req.headers?.['x-experiments'].split(',').forEach((element) => {
          const [elKey, elValue] = element.split('=');
          result[elKey] = elValue;
        });
        state.flags.experiments = result;
      }
    } catch (error) {
      console.error(error);
    }
    state.languages = await bff.getLanguages(50);
    state.language = state.languages.find(
      (language) => language.domain === app.$paths.host
    );
    state.languages = state.languages.map((lang) => ({
      ...lang,
      domain: `${app.$paths.urlEnvPrefix}${lang.domain}`,
    }));
    const { lang } = state.language;

    const airports = await bff.getAirports(state.language?.lang ?? 'en-us');
    state.airports = airports.filter(
      (airport) =>
        !!state.language?.status &&
        (!!state.language.is_minimal_product || airport.parking_count > 0)
    );
    state.inactiveAirports = airports.filter(
      (a) => !state.airports.map((b) => b.id).includes(a.id)
    );

    state.airport =
      state.airports.find(
        (_airport) => _airport.slug === route.params.airport
      ) ??
      state.inactiveAirports.find(
        (_airport) => _airport.slug === route.params.airport
      );

    const slugs = [
      'footer_airports',
      'footer_information',
      'footer_about',
      'footer_icons',
    ];
    const [
      airportData,
      airportStatistics,
      parkingsData,
      airportReviewsData,
      translationsData,
      homeContent,
      termsContent,
      policyContent,
      aboutContent,
      footerAirports,
      footerInformation,
      footerAbout,
      footerPaymentIcons,
      airportFAQData,
      headerConfiguration,
      reviewsPageContent,
      zendeskWhitelist,
      faqMeta,
      offers,
      internalLinks,
    ] = await Promise.all([
      bff.getAirportData(state.airport),
      bff.getAirportStatistics(state.airport, state.language),
      bff.getAirportParkings(state.language, state.airport),
      bff.getAirportReviews(state.airport, state.language),
      bff.getTranslations(state.language),
      bff.getPageContent('home', state.language),
      bff.getPageContent('terms-conditions', state.language),
      bff.getPageContent('privacy-policy', state.language),
      bff.getPageContent('about-us', state.language),
      ...slugs.map((_slug) => bff.getPageTemplate(_slug, state.language)),
      bff.getAirportFaq(state.airport, state.language),
      bff.getHeaderAirportsConfiguration(),
      bff.getAirportPageContent('reviews', state.language.id, state.airport.id),
      bff.getService('zendesk_whitelist'),
      bff.getPageContent('faq', state.language),
      bff.getOffers(
        state.airport,
        '12:00',
        '12:00',
        state.offerArrival,
        state.offerDeparture,
        state.language
      ),
      bff.getInternalLinks(state.airport.slug),
    ]);
    const airportDataContent = airportData.content;
    if (airportDataContent) {
      Object.keys(airportDataContent).forEach((langKey) => {
        airportDataContent[langKey].url = airportDataContent[
          langKey
        ].url.replace(/https:\/\//, `https://${app.$paths.urlEnvPrefix}`);
      });
      state.airportDataContentLanguages = Object.fromEntries(
        Object.entries(airportDataContent).filter(
          ([lang, content]) => content?.slug?.length
        )
      );
      state.airportData = {
        ...airportData,
        airportExtendedData: airportStatistics,
      };
      state.airportData.content = state.airportData.content[lang];
    }

    state.parkings = parkingsData || [];
    state.reviews =
      airportReviewsData &&
      (airportReviewsData.data?.[state.language.lang].sort((a, b) =>
        a.content.length > b.content.length ? -1 : 1
      ) ??
        []);
    state.reviewsMeta = airportReviewsData && airportReviewsData.meta;
    state.translations = translationsData;
    state.pageContent = {
      home: homeContent[lang],
      termsConditionsContent: termsContent[lang],
      privacyPolicyContent: policyContent[lang],
      aboutPageContent: aboutContent[lang],
    };

    state.pageTemplate = {
      footer_airports: footerAirports,
      footer_information: footerInformation,
      footer_about: footerAbout,
      footer_icons: footerPaymentIcons,
    };
    state.zendeskEnabled =
      zendeskWhitelist || zendeskWhitelist.includes(app.$paths.host);

    state.faq = airportFAQData;
    state.headerAirportsConfiguration = headerConfiguration;
    state.airportReviewsPageContent = reviewsPageContent;

    state.metaPages = {
      faqMeta: faqMeta[state.language.lang],
    };

    state.parkingsOffers = offers;

    state.internalLinks = internalLinks;

    if (!state.language.socials.facebook) {
      delete state.language.socials.facebook;
    }

    if (!state.language.socials.twitter) {
      delete state.language.socials.twitter;
    }

    // If no YouTube link is set in the socials fallback to the default
    if (!state.language.socials.youtube) {
      state.language.socials.youtube = 'https://www.youtube.com/parkosnl';
    }

    assignDataForDABucket(state, state.bucket);
    console.log('[nuxtServerInit] end ', req.originalUrl);
  },
};

export const strict = false;
