import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _151f8539 = () => interopDefault(import('../pages/old_airport.vue' /* webpackChunkName: "pages/old_airport" */))
const _08c77de9 = () => interopDefault(import('../pages/healthz/info.vue' /* webpackChunkName: "pages/healthz/info" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6f1b6d76 = () => interopDefault(import('../pages/_airport.vue' /* webpackChunkName: "pages/_airport" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/old_airport",
    component: _151f8539,
    name: "old_airport"
  }, {
    path: "/healthz/info",
    component: _08c77de9,
    name: "healthz-info"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/:airport",
    component: _6f1b6d76,
    name: "airport"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
