<!-- eslint-disable vuejs-accessibility/anchor-has-content -->
<template>
  <header class="bg-blue-600 relative leading-normal">
    <ReferralCashback />
    <div ref="highlightElement" class="bg-blue-600">
      <section
        :class="[
          'top-menu',
          {
            'top-menu--collapse': collapse,
            'top-menu--scrolled': scrollTop,
            'h-full': !collapse,
            flex: !collapse,
          },
        ]"
      >
        <div
          :class="[
            'top-menu__logo-wrapper',
            {
              'py-1': scrollTop && collapse,
              'py-4': !collapse || !scrollTop,
            },
          ]"
        >
          <div class="top-menu__logo-hyperlink__wrapper">
            <a href="/" class="top-menu__logo-hyperlink" @click="clear">
              <Logo />
            </a>
            <PartnerLogo />
          </div>
          <div class="top-menu__toggle-menu-wrapper">
            <a href="/login/" @click="clear">
              <IconUser class="top-menu__toggle-menu__user-icon" />
            </a>
            <ToggleMenu
              class="top-menu__toggle-menu"
              :open="!collapse"
              @toggled="toggleMenu"
            />
          </div>
        </div>

        <nav
          :class="[
            'header-nav',
            {
              'header-nav--collapse': collapse,
              'header-nav--mega': megaMenuOpen,
            },
          ]"
        >
          <div
            ref="scroller"
            :class="[
              'header-nav__scroller',
              {
                'overflow-y-scroll': !isSearchActive && !languagesExpanded,
                relative: isSearchActive || languagesExpanded,
              },
            ]"
          >
            <ul
              :class="[
                'header-nav__menu',
                {
                  'header-nav__menu--toggled': megaMenuOpen,
                  'hidden md:flex': languagesExpanded,
                },
              ]"
            >
              <li
                :class="[
                  'header-nav__menu__item',
                  {
                    'header-nav__menu__item--sub': megaMenuOpen,
                  },
                ]"
              >
                <div class="text-black md:text-white w-full md:w-auto">
                  <LazyHydrate when-idle>
                    <MegaMenu
                      :open="megaMenuOpen"
                      :expression="searchExpression"
                      @toggled="megaMenuToggled"
                    />
                  </LazyHydrate>
                </div>
              </li>
              <li v-if="zenDeskLangCode" class="header-nav__menu__item">
                <a
                  class="header-nav__menu__item__link"
                  :href="`https://parkos.zendesk.com/hc/${zenDeskLangCode}`"
                  rel="nofollow"
                  @click="clear"
                >
                  <div class="header-nav__menu__item__link--text">
                    {{ $i18n('general.customer-service') }}
                  </div>
                  <IconCaretRight class="header-nav__menu__item__link--icon" />
                </a>
              </li>
              <li v-if="aboutPageLink" class="header-nav__menu__item">
                <a
                  class="header-nav__menu__item__link"
                  :href="aboutPageLink"
                  @click="clear"
                >
                  <div class="header-nav__menu__item__link--text">
                    {{ aboutPageTitle }}
                  </div>

                  <IconCaretRight class="header-nav__menu__item__link--icon" />
                </a>
              </li>
            </ul>
            <ul
              class="header-nav__menu header-nav__menu--abs"
              :class="{
                'header-nav__menu--sub': languagesExpanded,
                'hidden md:flex': megaMenuOpen,
              }"
            >
              <li class="header-nav__menu__item header-nav__menu__item--login">
                <a
                  class="header-nav__menu__item__link"
                  href="/login/"
                  @click="clear"
                >
                  <div class="header-nav__menu__item__link--text">
                    {{ $i18n('templates.header-login') }}
                  </div>
                  <IconCaretRight class="header-nav__menu__item__link--icon" />
                </a>
              </li>
              <li
                v-if="faqMeta"
                class="header-nav__menu__item pr-0 header-nav__menu__item--meta"
              >
                <a
                  class="header-nav__menu__item__link"
                  :href="faqPageLink"
                  @click="clear"
                >
                  <div class="header-nav__menu__item__link--text">
                    {{ faqPageTitle }}
                  </div>
                  <IconCaretRight class="header-nav__menu__item__link--icon" />
                </a>
              </li>
              <li
                class="header-nav__menu__item pr-0"
                :class="{
                  'header-nav__menu__item--toggled': languagesExpanded,
                }"
              >
                <MobileLanguages
                  class="block md:hidden"
                  :expanded="languagesExpanded"
                  @toggled="languagesToggled"
                />
                <Languages
                  v-show="$device.isDesktop"
                  class="font-sans hidden md:flex items-center"
                />
              </li>
            </ul>
          </div>
        </nav>
      </section>
    </div>
    <div
      class="xs:container pt-83px md:pt-12 pb-18 px-5 md:px-10 md:py-20 relative"
    >
      <LazyHydrate
        when-idle
        when-visible
        :class="{
          'hidden md:block': $experimentVariant('reduce-font-size') === 'b',
        }"
      >
        <WorldMapParkos
          class="worldmap pointer-events-none max-w-3xl absolute -right-64 top-1/2 -mt-12"
        />
      </LazyHydrate>
      <LazyHydrate when-idle>
        <MetaReviews />
      </LazyHydrate>
      <div class="flex items-center">
        <h1
          class="text-lg md:text-3xl airport-title-main flex mt-8 mb-0 md:mb-2.5 md:mt-3 text-white font-cocogoose relative w-7/10 sm:w-auto"
        >
          {{ airport.maintitle }}
        </h1>
      </div>

      <div class="flex md:flex-row flex-col">
        <div
          v-if="avgPricePerDay"
          class="hidden md:block text-white text-lg font-sans"
        >
          {{ $i18n('templates.from-x-euro-day', { amount: avgPricePerDay }) }}
        </div>
        <div
          v-if="!showAirportsDropdown"
          class="mt-px cursor-pointer underline text-white text-md md:text-base"
          :class="{ 'md:ml-8': avgPricePerDay }"
          id="locations_datepicker_change_airport_link"
          @click.stop.prevent="changeAirport"
        >
          {{ $i18n('general.change-airport') }}
        </div>
      </div>
      <div class="flex flex-col md:block">
        <div class="inline-flex flex-col relative md:pr-16">
          <QuickBookBadge />
          <LazyHydrate when-idle when-visible>
            <ImprovedSearch
              ref="searchForm"
              class="mt-7"
              :show-airports="showAirportsDropdown"
            />
          </LazyHydrate>
        </div>

        <div
          class="flex flex-col xs:flex-row place-content-between mt-7 relative z-10"
          :class="{
            'max-w-4xl': !showAirportsDropdown,
            'max-w-6xl': showAirportsDropdown,
          }"
        >
          <div
            class="order-2 xs:order-1 header-checkmarks flex flex-col md:flex-row mt-5 xs:mt-0 text-[14px] font-normal"
          >
            <div
              class="my-2 md:my-0 font-sans text-md md:mr-10 text-white flex items-center"
            >
              <IconCheckmark class="icon-checkmark" />
              <div class="flex-1">
                {{ $i18n('templates.header-usp-1') }}
              </div>
            </div>
            <div
              class="my-2 md:my-0 font-sans text-md md:mr-10 text-white flex items-center"
            >
              <IconCheckmark class="icon-checkmark" />
              <div class="flex-1">
                {{ $i18n('templates.header-usp-2') }}
              </div>
            </div>
            <div
              class="my-2 md:my-0 font-sans text-md text-white flex items-center"
            >
              <IconCheckmark class="icon-checkmark" />
              <div class="flex-1">
                {{ $i18n('templates.header-usp-3') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import IconCheckmark from '~/components/icons/IconCheckmark.vue';
import Languages from '~/components/header/Languages.vue';
import ToggleMenu from '~/components/header/ToggleMenu.vue';
import IconCaretRight from '~/components/icons/IconCaretRight.vue';
import IconUser from '~/components/icons/IconUser.vue';
import MobileLanguages from '~/components/header/MobileLanguages.vue';
import ReferralCashback from '~/components/ReferralCashback.vue';
import QuickBookBadge from '~/components/airport/QuickBookBadgeSVG.vue';
import WorldMapParkos from '~/components/header/WorldMapParkos.vue';
import Logo from '../Logo.vue';
import PartnerLogo from './PartnerLogo.vue';

const MegaMenu = () => import('~/components/header/MegaMenu.vue');
const MetaReviews = () =>
  import('~/components/header/MetaReviews/MetaReviews.vue');
const ImprovedSearch = () =>
  import('~/components/experiments/tr-495-improved-date-picker/index.vue');

export default {
  components: {
    PartnerLogo,
    Logo,
    MegaMenu,
    MetaReviews,
    WorldMapParkos,
    ImprovedSearch,
    IconCheckmark,
    Languages,
    ToggleMenu,
    IconCaretRight,
    IconUser,
    MobileLanguages,
    LazyHydrate,
    ReferralCashback,
    QuickBookBadge,
  },
  data() {
    return {
      collapse: true,
      scrollTop: 0,
      megaMenuOpen: false,
      searchExpression: '',
      languagesExpanded: false,
      showAirportsDropdown: false,
      highlightHeight: null,
    };
  },
  computed: {
    airport() {
      return this.$store.state.airport;
    },
    language() {
      return this.$store.state.language;
    },
    offers() {
      const available = Object.values(
        this.$store.state.parkingsOffers.available
      );
      const unavailable = Object.values(
        this.$store.state.parkingsOffers.unavailable
      );

      // Sort available offers, placing merchants with 0 or undefined price at the end
      const sortedAvailable = available.sort((a, b) => {
        if (!a.price || a.price === 0) return 1;
        if (!b.price || b.price === 0) return -1;
        return a.price - b.price;
      });

      return [...sortedAvailable, ...unavailable];
    },
    avgPricePerDay() {
      if (
        this.$store.state?.airportData?.airportExtendedData?.avg_price_per_day
      ) {
        return new Intl.NumberFormat(this.language.lang, {
          style: 'currency',
          currency:
            this.offers?.[0]?.currency ??
            this.language.currency.iso_code ??
            'EUR',
        }).format(
          this.$store.state.airportData.airportExtendedData.avg_price_per_day
        );
      }
      return null;
    },
    zenDeskLangCode() {
      if (this.language && this.language.lang) {
        return this.language.zendesk_lang_code || this.language.lang;
      }
      return null;
    },
    aboutPageLink() {
      if (this.aboutPageContent && this.language && this.language.lang) {
        const currentContent = this.aboutPageContent;
        return `/${currentContent.slug}.html`;
      }
      return null;
    },
    aboutPageTitle() {
      if (this.aboutPageContent && this.language && this.language.lang) {
        const currentContent = this.aboutPageContent;
        return currentContent.title;
      }
      return null;
    },
    aboutPageContent() {
      return this.$store.state.pageContent.aboutPageContent;
    },
    metaPages() {
      return this.$store.state.metaPages;
    },
    faqMeta() {
      return this.metaPages.faqMeta;
    },
    faqPageLink() {
      return `//${this.$paths.host}/${this.faqMeta.slug}/`;
    },
    faqPageTitle() {
      return this.faqMeta.title;
    },
    isSearchActive() {
      return !!(this.searchExpression && this.searchExpression !== '');
    },
    headerTopOffset() {
      if (this.highlightHeight !== null) {
        const top = this.highlightHeight - this.scrollTop;
        return `${top > 0 ? top : 0}px`;
      }
      return '';
    },
    isUSDomain() {
      return this.$store.state.language.lang === 'en-us';
    },
  },
  mounted() {
    document.addEventListener('scroll', this.scrollCheck, { passive: true });
  },
  methods: {
    screens(rules, defaultValue) {
      if (process.server) {
        return defaultValue;
      }
      return this.$screens(rules, defaultValue);
    },
    scrollCheck() {
      this.scrollTop = window.scrollY;
      if (window.scrollY <= 0) {
        document.querySelector('html').dataset.scroll = 0;
      }
      document.querySelector('html').dataset.scroll = window.scrollY;

      if (document.querySelector('body > div:not(#__nuxt):first-child')) {
        this.highlightHeight = document.querySelector(
          'body > div:not(#__nuxt):first-child'
        )?.clientHeight;
      }
    },
    toggleMenu(open) {
      this.collapse = !open;
      const { scroller } = this.$refs;
      if (this.collapse) {
        this.$clearAllBodyScrollLocks();
        this.resetData();
      } else {
        this.$disableBodyScroll(scroller);
      }
    },
    megaMenuToggled(show) {
      this.megaMenuOpen = show;
      if (show && this.$screens({ md: true }, false)) {
        this.$refs.highlightElement.skipScrollLock = this.$screens(
          { md: true },
          false
        );
        this.$store.commit('highlight/show', this.$refs.highlightElement);
      } else if (this.$screens({ md: true }, false)) {
        this.$store.dispatch('highlight/hide');
      }
    },
    clear() {
      this.toggleMenu();
    },
    languagesToggled(expanded) {
      this.languagesExpanded = expanded;
      this.$disableBodyScroll(this.$refs.scroller);
    },
    resetData() {
      this.searchExpression = '';
      this.megaMenuOpen = false;
      this.languagesExpanded = false;
      this.$store.dispatch('highlight/hide');
    },
    changeAirport() {
      this.showAirportsDropdown = true;
      this.$nextTick(() => {
        if (this.$refs.searchForm) this.$refs.searchForm.handleAirportToggle();
      });
    },
  },
};
</script>

<style lang="scss">
.header-padding {
  transition: all 0.2s;
  @apply py-1;
}
html[data-scroll='0'] {
  .header-padding {
    transition: all 0.2s;
    @apply py-4;
  }
}
h1.airport-title-main {
  max-width: 90%;
}

.worldmap {
  transform: translate3d(0, -50%, 0);
  width: 100%;
}

.top-menu {
  @apply md:container md:h-auto fixed md:static md:py-7 md:px-10 bg-blue-600 md:bg-transparent w-full md:flex flex-col md:flex-row md:items-center z-30;

  &--collapse {
    @apply place-content-between h-auto;
  }

  &__search {
    @apply block md:hidden;
  }

  &__logo {
    &-wrapper {
      @apply w-full md:py-0 md:w-auto flex place-content-between px-3 md:px-0 transition-all duration-200;
    }
    &-hyperlink {
      &__wrapper {
        @apply flex items-center;
      }

      @apply p-3 md:-ml-3 -ml-4px;
    }
  }

  &__toggle-menu {
    @apply md:hidden;

    &-wrapper {
      @apply inline-flex items-center;
    }

    &__user-icon {
      @apply mr-3 md:hidden w-5 h-5;
    }
  }
}

.header-nav {
  @apply md:h-auto text-xl md:text-base w-screen h-0 flex-1 flex flex-col md:static top-36 left-0 right-0 bg-white md:bg-transparent overscroll-contain;

  $self: &;

  a {
    &:hover {
      @media (min-width: 1080px) {
        @apply underline;
      }
    }
  }

  &--collapse {
    @apply hidden md:flex h-auto;
  }

  &--mega {
    @apply top-18;
  }

  &__scroller {
    @apply h-full md:overflow-y-visible w-full flex flex-col md:static md:flex-row md:items-center items-stretch overscroll-contain;
  }

  &__menu {
    @apply md:mx-auto flex md:flex-row flex-col min-h-max;

    $self: &;

    &:first-child {
      min-height: max-content;
    }

    &--toggled {
      #{$self}__item {
        @apply hidden md:flex;
        &--sub {
          @apply flex;
        }
      }
    }

    &--abs {
      @apply m-0 flex md:flex-row flex-col;
    }

    &__item {
      @apply flex flex-1 border-b border-athens md:border-none select-none w-full xs:w-auto;
      min-height: max-content;

      &--login {
        @apply hidden md:flex;
      }

      &--meta {
        @apply md:hidden;
      }

      &__link {
        @apply py-6 place-content-between items-center w-full px-6 md:w-auto md:px-3 md:py-2 text-black md:text-white font-sans flex justify-between cursor-pointer;

        &:hover {
          @apply no-underline;
        }

        &--text {
          @apply shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2;
        }

        &--icon {
          @apply ml-auto text-black-alt-500 block md:hidden;
        }
      }
    }
  }
}

.icon-checkmark {
  @apply text-blue-alt mr-2 md:mr-3 md:scale-150 lg:scale-100 transform;
}
</style>
