<template>
  <div class="footer" id="locations_footer">
    <Support />
    <div class="font-sans text-white bg-blue-600 pb-10 pt-4 sm:py-20">
      <div class="xs:container px-5 sm:px-10">
        <div class="flex place-content-between md:flex-row flex-col">
          <div class="md:order-1 order-2 mt-6 sm:mt-0">
            <Logo class="mb-6" />
            <div
              class="flex md:flex-col sm:flex-row flex-col sm:place-content-between md:place-content-start"
            >
              <div class="cms-payment-icons">
                <!-- <div>
                  {{ $i18n('templates.trusted-payment') }}
                </div> -->
                <div>
                  <!-- <div class="flex mt-4"> -->
                  <!-- <img
                    v-for="paymentIcon in paymentIcons"
                    :key="paymentIcon"
                    class="w-8 h-auto mr-3"
                    :src="`${$paths.assetsUrl}img/payment-icons/${paymentIcon}.png`"
                  > -->
                  <PageTemplate
                    class="text-[12px] xs:text-md md:text-base"
                    :slug="'footer_icons'"
                    :language="language"
                  />
                </div>
              </div>
              <div class="mt-5 sm:mt-0 md:mt-5">
                <div class="text-[12px] xs:text-md md:text-base">
                  {{ $i18n('general.socials-follow-us') }}
                </div>
                <div class="mt-4">
                  <Socials />
                </div>
              </div>
            </div>
          </div>
          <div
            class="footer__footer-templates grid grid-cols-1 xs:grid-cols-2 gap-0 md:gap-6 xs:flex place-content-between md:w-6/12 mb-6 md:order-2 order-1 xs:gap-4"
          >
            <template v-for="slug in slugs">
              <PageTemplate
                ref="templates"
                :key="slug"
                class="hidden xs:block"
                :slug="slug"
                :language="language"
              />
            </template>
            <template v-for="(slug, index) in slugs">
              <ToggleInfo
                v-if="parsedContent[slug]"
                :key="`${slug}alt`"
                ref="toggles"
                :data-slug="slug"
                class="xs:hidden border-b border-cornflower"
                :caret-active-class="`text-white`"
                :narrow="true"
                :class="{ '-mt-4': !index }"
              >
                <template #title>
                  {{ parsedContent[slug].title }}
                </template>
                <template #content>
                  <ul>
                    <template v-for="data in parsedContent[slug].content">
                      <li :key="data.url" :style="data.parentStyle">
                        <template v-if="data.img">
                          <div v-html="data.img" />
                        </template>
                        <template v-else>
                          <a
                            class="text-white text-[14px] xs:text-base"
                            :href="data.url"
                          >
                            {{ data.text }}
                          </a>
                        </template>
                      </li>
                    </template>
                  </ul>
                </template>
              </ToggleInfo>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-chathams-blue-900 py-6 text-white font-sans">
      <div class="xs:container px-5 sm:px-10">
        <div
          class="flex flex-col items-start md:flex-row place-content-between"
        >
          <div
            class="order-2 text-[12px] xs:text-md md:text-base md:order-1 mt-4 md:mt-0"
            v-html="$i18n('templates.copyright')"
          />
          <div class="order-1 md-order-2 flex xs:flex-row">
            <a
              v-if="termsConditionsPageContent.url"
              :href="termsConditionsPageContent.url"
              class="text-white text-[12px] xs:text-md hover:text-white hover:underline"
              >{{ termsConditionsPageContent.title }}</a
            >
            <a
              v-if="privacyPolicyPageContent.url"
              :href="privacyPolicyPageContent.url"
              class="ml-12 text-[12px] xs:text-md text-white hover:text-white hover:underline"
              >{{ privacyPolicyPageContent.title }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '~/components/Logo.vue';
import Socials from '~/components/Socials.vue';
import ToggleInfo from '~/components/airport/ToggleInfo.vue';
import PageTemplate from '~/components/PageTemplate.vue';
import Support from '~/components/footer/Support.vue';

export default {
  components: {
    ToggleInfo,
    Support,
    Logo,
    Socials,
    PageTemplate,
  },
  data() {
    return {
      paymentIcons: [
        'payment_ideal',
        'payment_amex',
        'payment_mastercard',
        'payment_paypal',
        'payment_visa',
        'payment_star',
      ],
      parsedContent: {},
    };
  },
  computed: {
    slugs() {
      return ['footer_airports', 'footer_information', 'footer_about'];
    },
    language() {
      return this.$store.state.language;
    },
    termsConditionsContent() {
      return this.$store.state.pageContent.termsConditionsContent;
    },
    privacyPolicyContent() {
      return this.$store.state.pageContent.privacyPolicyContent;
    },
    termsConditionsPageContent() {
      if (this.termsConditionsContent && this.language && this.language.lang) {
        const currentContent = this.termsConditionsContent;
        return {
          title: currentContent.title,
          url: `/${currentContent.slug}.html`,
        };
      }

      return { title: '', url: '' };
    },
    privacyPolicyPageContent() {
      if (this.privacyPolicyContent && this.language && this.language.lang) {
        const currentContent = this.privacyPolicyContent;
        return {
          title: currentContent.title,
          url: `/${currentContent.slug}.html`,
        };
      }

      return { title: '', url: '' };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.templates.forEach((comp) => {
        const parsed = this.parseContent(comp.$el);
        this.parsedContent[comp.slug] = parsed;
      });
      this.parsedContent = { ...this.parsedContent };
    });
  },
  methods: {
    parseContent(ulElement) {
      try {
        if (!ulElement) {
          return undefined;
        }
        const title = ulElement.querySelector('li:first-child');
        const content = ulElement.querySelectorAll('li:not(:first-child)');
        if (!title || !content) {
          return null;
        }
        const data = [];
        content.forEach((e) => {
          const a = e.querySelector('a');
          if (a) {
            data.push({ text: e.textContent, url: a.href });
          } else {
            const img = e.querySelector('img');
            if (img) {
              data.push({
                img: img.outerHTML,
                parentStyle:
                  (e &&
                    e.attributes &&
                    e.attributes.style &&
                    e.attributes.style.value) ||
                  '',
              });
            }
          }
        });
        return { title: title.textContent, content: data };
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex);
      }
      return {};
    },
  },
};
</script>

<style lang="scss">
.footer {
  content-visibility: auto;

  &__footer-templates {
    @media all and (max-width: 360px) {
      @apply grid-cols-1;
    }

    strong,
    b {
      @apply font-cocogoose;
    }

    ul {
      li {
        @apply mb-3 font-sans;
        @media (min-width: 576px) {
          &:first-child {
            @apply mb-5 font-sans;
          }
        }
        a {
          &:hover {
            @apply underline;
          }
        }
      }
    }
  }

  .cms-payment-icons {
    @apply font-sans;
    .text-center {
      @apply text-left;
    }
    ul {
      @apply place-content-start mt-3 gap-y-3;
      .icon {
        &.px-4 {
          @apply px-1 py-0 rounded bg-white mr-3;
        }
        img {
          @apply w-auto h-6;
        }
      }

      & + div {
        @apply mt-3;
        & > img {
          @apply hidden;
        }
      }
    }
  }
}
</style>
