<template>
  <div v-if="partner && partnerLogoSrc" id="header-partner" class="scale-75 xxs:scale-100 h-16 px-3 xxs:px-5 text-center hidden-xs hidden-sm">
    <span class="text-xs text-white whitespace-nowrap text-ellipsis overflow-ellipsis overflow-hidden">{{ $i18n('general.in-cooperation') }}</span>
    <img :src="partnerLogoSrc" alt="Partner" height="auto" width="auto" class="max-h-10">
  </div>
</template>

<script>
export default {
  data() {
    return {
      partner: false,
    };
  },
  computed: {
    partnerLogoSrc() {
      if (this.partner) {
        return `${this.$config.partnerLogoBaseUrl}${this.partner}.png`;
      }
      return null;
    },
  },
  mounted() {
    if (this.readCookie()) {
      this.partner = this.readCookie();
    } else if (this.readQuery()) {
      const params = {
        utm_source: this.$route.query.utm_source,
        utm_medium: this.$route.query.utm_medium,
        utm_campaign: this.$route.query.utm_campaign,
      };

      this.$axios({
        method: 'get',
        baseURL: '/',
        url: 'ajax/set-partner-session',
        params,
      }).then((result) => {
        if (result.data.result && result.data.result !== 'false') {
          this.partner = this.readQuery();
        } else {
          this.partner = false;
        }
      }).catch((error) => {
        // Add error handeling for failing partner links
        console.log(error);
      });
    }
  },
  methods: {
    readQuery() {
      if (this.$route.query.utm_source && this.$route.query.utm_medium && this.$route.query.utm_campaign) {
        return this.$route.query.utm_campaign;
      }
      return false;
    },
    readCookie() {
      const nameEQ = 'partnerImage=';
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return false;
    },
  },
};
</script>
