<template>
    <img src="~/static/general/facebook.svg" loading="lazy" alt="facebook">
</template>

<script>
export default {

};
</script>

<style>

</style>
