<template>
  <section>{{ error }}</section>
</template>

<script>
export default {
  head() {
    const script = [];

    if (this.language.extra_gtm_key) {
      const attrs = {
        hid: 'ExtraGTM',
        src: `https://www.googletagmanager.com/gtag/js?id=${this.language.extra_gtm_key}`,
        async: true,
      };
      script.push(attrs);
    }

    return {
      script
    };
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
  },
  layout: 'error',
  props: ['error'],
};
</script>
