<template>
  <div :class="['md:text-center py-2.5 xs:static', backgroundColor, textColor]">
    <h5 class="text-[12px] md:text-[14px] font-normal m-0 mx-5">
      {{ $i18n('general.urgency-may-campaign') }}
    </h5>
  </div>
</template>

<script>
export default {
  name: 'UrgencyBanner',
  props: {
    backgroundColor: {
      type: String,
      default: 'bg-[#fff8e7]',
    },
    textColor: {
      type: String,
      default: 'text-[#0E1017]',
    },
  },
  mounted() {
    if (this.$screens({ xs: false }, true)) {
      let bannerHeight = this.$el.offsetHeight;
      this.$nextTick(() => {
        bannerHeight = this.$el.offsetHeight;
      });
      window.addEventListener('scroll', () => {
        this.$el.classList.add('absolute');
        if (window.scrollY >= bannerHeight) {
          this.$el.parentElement.style.paddingTop = 0;
          this.$el.style.top = `${bannerHeight}px`;
        } else {
          this.$el.parentElement.style.paddingTop = `${
            bannerHeight - window.scrollY
          }px`;
          this.$el.style.top = `${0 - window.scrollY}px`;
        }
      });
    }
  },
};
</script>
