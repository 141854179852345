<template>
 <img src="~/static/general/youtube.svg" loading="lazy" alt="youtbe">
</template>

<script>
export default {

};
</script>

<style>

</style>
