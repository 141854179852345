<template>
  <fragment>
    <slot v-if="active" :name="variant" />
    <slot v-else name="a" />
  </fragment>
</template>

<script>
import { Fragment } from 'vue-fragment';

export default {
  name: 'ExperimentsToggle',
  components: {
    Fragment,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    active() {
      return this.$store?.getters?.isExperimentEnabled(this.name);
    },
    variant() {
      return this.$store?.getters?.experimentVariant(this.name);
    },
  },
  mounted() {
    this.$store.commit('experiments/addExperiment', {
      name: this.name,
      variant: this.variant,
    });
  },
  methods: {
  },
};
</script>
