import meanBy from 'lodash.meanby';
import mean from 'lodash.mean';
import sortBy from 'lodash.sortby';

export const state = () => ({});
export const getters = {
  averagePricePerWeek: (state, getters, rootState) => {
    const offers = Object.values(rootState.parkingsOffers.available);
    const validOffers = offers.filter(
      (offer) => offer.price && +offer.price > 0
    );
    const price =
      validOffers.length > 0
        ? meanBy(validOffers, (m) => +m.price).toFixed(2)
        : '0.00';
    return { price, currency: validOffers[0]?.currency };
  },
  bestRatedParkings: (state, getters, rootState) => {
    const available = Object.values(rootState.parkingsOffers.available);
    const unavailable = Object.values(rootState.parkingsOffers.unavailable);

    const offers = [...available, ...unavailable];

    const offersReviews = offers.filter(
      (offer) => offer.price && offer.review.count
    );
    const sortedOffers = sortBy(offersReviews, (offer) =>
      mean([offer.review.score])
    ).reverse();

    return sortedOffers.slice(0, 3);
  },
  cheapestParkings: (state, getters, rootState) => {
    const availableOffers = Object.values(rootState.parkingsOffers.available);
    const offers = availableOffers.filter((offer) => offer.price);
    const sortedOffers = sortBy(offers, (offer) => +offer.price);

    return sortedOffers.slice(0, 3);
  },
};
