/* eslint-disable comma-dangle */
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';

class BffService {
  constructor(config = {}) {
    this.pages = [];
    this.pageContent = [];
    this.axiosInstance = axios.create({
      baseURL: process.env.BFF_BASE_URL,
    });
    // log https errors
    this.axiosInstance.interceptors.request.use(
      (request) => {
        request.headers = {
          ...request.headers,
          'User-Agent': this.getUserAgent(),
          ...(config?.headers || {}),
        };

        return request;
      },
      (error) => {
        console.log('BFF Request Error: ', JSON.stringify(error));
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log('BFF Response Error:  ', JSON.stringify(error));
        return Promise.reject(error);
      }
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getUserAgent() {
    const appName = 'ParkosAirportPages';
    const projectName = 'airport-pages';
    const { VERSION, ENVIRONMENT } = process.env;

    if (VERSION && ENVIRONMENT) {
      return `${appName}/${VERSION} (+https://${projectName}.${ENVIRONMENT}.parkos.dev/)`;
    }

    return `${appName}/development`;
  }

  async getLanguages(limit = 50) {
    const self = this;

    const res = await self.axiosInstance.get('languages', {
      params: {
        limit,
      },
    });
    return res.data.data;
  }

  async getTranslations(language) {
    const self = this;
    const res = await self.axiosInstance.get(
      `translations/${language.lang}/airport`
    );
    return res.data;
  }

  async getAirports(lang, limit = 250, orderBy = 'locations_content.name') {
    const self = this;

    const res = await self.axiosInstance.get('airports', {
      params: {
        lang,
        limit,
        orderBy,
      },
    });

    return res.data.data;
  }

  static getAirport(slug, airports) {
    return Array.prototype.find.call(
      airports,
      (_airport) => _airport.slug === slug
    );
  }

  async getAirportData(airport) {
    const self = this;

    const res = await self.axiosInstance.get(`airports/${airport.id}/details`);

    return {
      airport,
      content: res.data.content,
    };
  }

  async getAirportReviews(airport, language, limit = 10) {
    try {
      const self = this;
      const res = await self.axiosInstance.get('airport/reviews', {
        params: {
          airport: airport.id,
          lang: language.lang ?? 'en-eu',
          limit,
        },
      });

      return res.data;
    } catch (error) {
      console.info(error);
      return {};
    }
  }

  async getAirportPageContent(devtitle, lang, airport) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`airports/${devtitle}/content?airport=${airport}&lang=${lang}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async getHeaderAirportsConfiguration() {
    const { axiosInstance } = this;
    return new Promise((resolve, reject) => {
      axiosInstance
        .get('configurations/header')
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getAirportFaq(airport, language) {
    const self = this;

    const res = await self.axiosInstance.get('airport/faq', {
      params: {
        airport: airport.id,
        lang: language.id,
      },
    });

    return res.data;
  }

  async getAirportParkings(language, airport, limit = 100) {
    const self = this;

    const res = await self.axiosInstance.get('parkings', {
      params: {
        lang: language.lang,
        airport: airport.id,
        limit,
      },
    });

    return res.data;
  }

  async getPageTemplate(slug, language) {
    const self = this;

    const res = await self.axiosInstance.get('page_template', {
      params: { slug, lang: language.lang },
    });

    return res.data.data[language.lang];
  }

  async getPageContent(devtitle, language) {
    const self = this;

    const res = await self.axiosInstance.get(`pages/${devtitle}/content.json`, {
      params: { lang: language.lang },
    });

    return res.data;
  }

  async getAirportStatistics(airport, language) {
    const self = this;

    const domain = language.domain ?? null;
    const airportId = airport.id ?? null;
    const langId = language.id ?? null;

    const res = await self.axiosInstance.get(
      `airports/${airport.slug}/statistics.json?domain=${domain}&airport=${airportId}&langId=${langId}`
    );

    return res.data;
  }

  async getService(service) {
    const self = this;
    const res = await self.axiosInstance.get(`services/${service}`);
    return res.data;
  }

  async getOffers(
    airport,
    arrivalTime,
    departureTime,
    arrival,
    departure,
    language
  ) {
    const self = this;

    const res = await self.axiosInstance.get('offers', {
      params: {
        location: airport.slug,
        arrivalTime,
        departureTime,
        arrival,
        departure,
        lang: language.lang,
      },
    });
    return res.data;
  }

  async getInternalLinks(slug) {
    const self = this;
    const res = await self.axiosInstance.get(`airport/${slug}/internal-links`);
    return res.data;
  }
}

const bffInstances = {};

// eslint-disable-next-line import/prefer-default-export
export function getInstance(name, config) {
  if (!(name in bffInstances)) {
    bffInstances[name] = new BffService(config);
  }

  return bffInstances[name];
}
