<template>
  <div>
    <img v-if="isUSDomain" :src="`${$paths.assetsUrl}img/usa-map.png`" alt="" class="hidden md:inline">
    <img v-else :src="`${$paths.assetsUrl}img/worldmap-plane.svg`" alt="" class="hidden md:inline">
  </div>
</template>

<script>
export default {
  computed: {
    isUSDomain() {
      return this.$store.state.language.lang === 'en-us';
    },
  },
};
</script>
