<template>
  <div v-if="cookieData && !close" class="referral-cashback-banner fixed bottom-0 z-50 inset-x-0 md:static flex items-center">
    <div class="referral-cashback-banner__text xs:container px-5 sm:px-10 flex items-center py-3 lg:py-0 text-sm">
      <img alt="sale outline" src="~/static/general/sale-outline.svg" class="mr-5" loading="lazy">
      {{ $i18n('customer.header-cashback-discount-percentage' ,{name:nameCapitalized,percentage:discount}) }}
      <img
        role="button"
        tabindex="0"
        alt="close"
        src="~/static/general/close-icon.svg"
        class="pl-5 ml-auto p-2 cursor-pointer"
        loading="lazy"
        @keypress.stop.passive="close = true"
        @click.stop.passive="close = true"
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      close: false,
    };
  },
  computed: {
    cookieData() {
      return this.$store.state.referralCashback.data;
    },
    discount() {
      return this.cookieData?.discount_percentage;
    },
    name() {
      return this.cookieData?.name;
    },
    nameCapitalized() {
      if (!this.name) { return undefined; }

      return this.name.trim().split(' ').map((n) => {
        const arr = n.split('');
        arr.shift();
        return `${n[0].toLocaleUpperCase()}${arr.join('')}`;
      }).join(' ');
    },
  },
};
</script>

<style lang="scss">
.referral-cashback-banner {
  background-color:#FEF5EC;
  @media(min-width:1189px){
    height:50px;
  }

  &__text{
    color:#0E1017;
  }
}
</style>
