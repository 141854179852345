let elements = [];

const scrollEvents = [
  'wheel',
  'touchmove',
  'pointermove',
  'DOMMouseScroll',
];

let swipeY = null;
let swipeX = null;

function listenTouchStart(event) {
  const [touch] = event.touches;
  if (!touch) {
    return;
  }
  swipeY = touch.clientY;
  swipeX = touch.clientX;
}

function lockScroll(event) {
  const [touch] = event.touches || [];
  let swipingUp = null;
  if (touch) {
    // if it is a horizontal scroll, ignore
    if (Math.abs(touch.clientX - swipeX) > Math.abs(touch.clientY - swipeY)) {
      return;
    }
    if (touch && swipeY !== null) {
      if (touch.clientY < swipeY) {
        swipingUp = true;
      } else {
        swipingUp = false;
      }
    }
  }
  const [relatedElement] = elements.filter((e) => e.contains(event.target) || e === event.target);
  if (!relatedElement) {
    event.preventDefault();
    return;
  }
  const overscrollingTop = relatedElement.scrollHeight - relatedElement.scrollTop === relatedElement.clientHeight;
  const overscrollingBot = relatedElement.scrollHeight - relatedElement.scrollTop === relatedElement.scrollHeight;
  if ((overscrollingBot && swipingUp === false) || (overscrollingTop && swipingUp === true)) {
    event.preventDefault();
  }
}

function preventLayoutXShift() {
  document.querySelectorAll('.overflow-x-hidden').forEach((e) => {
    e.scrollTo(0, e.scrollTop);
  });
}

function hideBodyOverflow(shouldPreventLayoutXShift) {
  document.body.classList.add('overflow-hidden');
  // prevent horizontal layout shift when or after showing modal in IOS13
  if (shouldPreventLayoutXShift) {
    setTimeout(() => {
      preventLayoutXShift();
    }, 100);
  }
}

function clearBodyOverflow(shouldPreventLayoutXShift) {
  document.body.classList.remove('overflow-hidden');

  // prevent horizontal layout shift when or after showing modal in IOS13
  if (shouldPreventLayoutXShift) {
    preventLayoutXShift();
  }
}
export default ({}, inject) => {
  if (process.server) {
    return;
  }

  const isIosDevice = typeof window !== 'undefined'
  && window.navigator
  && window.navigator.platform
  && (/iP(ad|hone|od)/.test(window.navigator.platform)
    || (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1));
  const shouldPreventLayoutXShift = isIosDevice && (navigator.userAgent.includes('Version/13.') || navigator.userAgent.includes('Version/12.'));

  inject('enableBodyScroll', (el) => {
    clearBodyOverflow(shouldPreventLayoutXShift);
    elements = elements.filter((e) => e !== el);
    scrollEvents.forEach((e) => {
      document.removeEventListener(e, lockScroll, { capture: true, passive: false });
    });
    document.removeEventListener('touchstart', listenTouchStart);
  });

  inject('disableBodyScroll', (el) => {
    if (isIosDevice) {
      elements.push(el);
      scrollEvents.forEach((e) => {
        document.addEventListener(e, lockScroll, { capture: true, passive: false });
      });
      document.addEventListener('touchstart', listenTouchStart);
    }
    hideBodyOverflow(shouldPreventLayoutXShift);
  });

  inject('clearAllBodyScrollLocks', () => {
    clearBodyOverflow(isIosDevice);
    elements = [];
    scrollEvents.forEach((e) => {
      document.removeEventListener(e, lockScroll, { capture: true, passive: false });
    });
    document.removeEventListener('touchstart', listenTouchStart);
  });
};
