import axios from 'axios';
import https from 'https';

export default function ({ app }, inject) {
  const axiosInstance = axios.create({
    httpsAgent: new https.Agent({ rejectUnauthorized: false }),
  });

  if (process.env.NODE_ENV !== 'production') {
    axiosInstance.interceptors.request.use((config) => {
      let params = '';
      if (config.params) {
        params = Object.keys(config.params)
          .map((key) => [key, config.params[key]].join('='))
          .join('&');
      }

      console.log(`Making request to ${config.baseURL}${config.url}?${params}`);
      return config;
    });

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log(`Error while making request ${error.message}`);
        return Promise.reject(error);
      }
    );
  }

  // Inject the axios instance as $axios to avoid conflicts
  inject('axios', axiosInstance);
}
