/* eslint-disable no-unused-vars */
import Vue from 'vue';
import Zendesk from '@dansmaculotte/vue-zendesk';
import { Plugin } from '@nuxt/types';

interface ZendeskOptions {
  $on(event: string, callback: CallableFunction): void;
  load(): void;
  hide(): void;
  show(): void;
  logout(): void;
  identify(user: string): void;
  prefill(user: string): void;
  setLocale(locale: string): void;
  updateSettings(settings: any): void;
  clear(): void;
  updatePath(options: any): void;
  toggle(): void;
  reset(): void;
  close(): void;
  open(): void;
}

declare module 'vue/types/vue' {
  interface Vue {
    $zendesk: ZendeskOptions;
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $zendesk: ZendeskOptions;
  }
  interface Context {
    $zendesk: ZendeskOptions;
  }
}

const zendeskPlugin: Plugin = ({ $i18n, $config }) => {
  Vue.use(Zendesk, {
    key: $config.zendeskKey,
    disabled: true,
    hideOnLoad: true,
    settings: {
      webWidget: {
        helpCenter: {
          title: {
            '*': $i18n('general.help'),
          },
          searchPlaceholder: {
            '*': $i18n('templates.blog-header'),
          },
        },
        color: {
          theme: '#0a83ef',
        },
      },
    },
  });
};

export default zendeskPlugin;
