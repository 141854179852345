<template>
  <div class="overflow-x-hidden">
    <main>
      <Nuxt />
    </main>
  </div>
</template>

<script>
export default {
  head() {
    const script = [];

    if (this.language.extra_gtm_key) {
      const attrs = {
        hid: 'ExtraGTM',
        src: `https://www.googletagmanager.com/gtag/js?id=${this.language.extra_gtm_key}`,
        async: true,
      };
      script.push(attrs);
    }

    return {
      script,
    };
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
  },
};
</script>

<style>
* {
  -webkit-font-smoothing: antialiased;
}

.skew {
  transform: skew(0deg, -1deg);
}

.unskew {
  transform: skew(0deg, 1deg);
}

.skew,
.unskew {
  backface-visibility: hidden;
}
</style>
