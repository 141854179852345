import type { Plugin } from '@nuxt/types';
import { Paths } from '~/types/Paths';

declare module 'vue/types/vue' {
  interface Vue {
    $paths: Paths;
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $paths: Paths;
  }

  interface Context {
    $paths: Paths;
  }
}

const pathsPlugin: Plugin = ({ req }, inject) => {
  const headers = req && req.headers ? { ...req.headers } : {};
  let host: string | undefined;

  if (process.env.HOST_MODE === 'env' && process.env.HOST) {
    host = process.env.HOST;
  } else if (headers[process.env.HOST_MODE as string]) {
    host = headers[process.env.HOST_MODE as string] as string;
  } else {
    host = 'eu.parkos.com';
  }

  if (process.client) {
    const clientUrl: string | string[] = window.location.href;
    host = clientUrl
      .split('/')[2]
      .replace(/\.?test|staging\.?|www\.?|:[0-9]+/g, '');
  }

  let urlEnvPrefix;

  if (process.server) {
    urlEnvPrefix = req.headers.host?.includes('staging') ? 'staging.' : '';
  } else {
    host = window.location.host;
    urlEnvPrefix = window.location.host?.includes('staging') ? 'staging.' : '';
  }

  host = host?.replace(/\.?test|staging\.?|www\.?|:[0-9]+/g, '');

  if (!host?.match(/^(?:([a-z-])+)?\.?parkos\.[a-z]+(?:\.[a-z]+)?/g)?.length) {
    host = 'eu.parkos.com';
  }

  const paths = {
    host,
    urlEnvPrefix,
    protocol: 'https',
    url: (trailingSlash: boolean = true) =>
      `${paths.protocol}://${paths.host}${trailingSlash ? '/' : ''}`,
    assetsUrl: 'https://assets.parkos.com/assets/',
  };

  inject('paths', paths);
};

export default pathsPlugin;
