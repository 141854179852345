import { Plugin } from '@nuxt/types';

interface ReplacerObject {
  [key: string]: string;
}

type I18nFunction = (key: string, replace?: ReplacerObject) => string;

declare module 'vue/types/vue' {
  interface Vue {
    $i18n: I18nFunction;
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $i18n: I18nFunction;
  }
  interface Context {
    $i18n: I18nFunction;
  }
}

const i18nPlugin: Plugin = ({ store }, inject) => {
  const i18n: Function = (
    key: string,
    replace: ReplacerObject = {}
  ): string => {
    let translation = key
      .split('.')
      .reduce((t: any, i: any) => t[i] ?? null, store.state.translations);

    if (!translation) return key;

    // eslint-disable-next-line no-restricted-syntax
    for (const placeholder of Object.keys(replace)) {
      translation = translation.replaceAll(
        `:${placeholder}`,
        replace[placeholder]
      );
    }

    return translation;
  };

  inject('i18n', i18n);
};

export default i18nPlugin;
